<template lang='pug'>
.page.result
  .page_bg_img
    img(src='../assets/img/lv_bg_01.png', v-if='lv === 1')
    img(src='../assets/img/lv_bg_02.png', v-else-if='lv === 2')
    img(src='../assets/img/lv_bg_03.png', v-else-if='lv === 3')
    img(src='../assets/img/lv_bg_04.png', v-else-if='lv === 4')
    img(src='../assets/img/lv_bg_05.png', v-else-if='lv === 5')
    img(src='../assets/img/lv_bg_06.png', v-else-if='lv === 6')
    img(src='../assets/img/lv_bg_07.png', v-else-if='lv === 7')
    img(src='../assets/img/lv_bg_08.png', v-else-if='lv === 8')
    img(src='../assets/img/lv_bg_09.png', v-else-if='lv === 9')
    img(src='../assets/img/lv_bg_010.png', v-else-if='lv === 10')
  .logo
    img(src='../assets/img/logo_white.png')
  .card
    .page_qrcode
      .img
        img(src='../assets/img/url_qrcode.png')
      .share_line
        img(src='../assets/img/share_line_icon.png')
        span 截图分享
    .user_head_img
      img(:src='userInfo.avatarUrl')
    .nickname {{ userInfo.nickName }}
    .title 恭喜你，完成测评！
    //- .txt 我的测评结果
    .info
      .gary 理性度
        span {{ score }}
        i %
      .gary 感性度
        span {{ 100 - score }}
        i %
    .txt.e 理性度超过&nbsp;
      span {{ proportion }}%&nbsp;
      | 的答题者，感性度超过&nbsp;
      span {{ 100 - proportion }}%&nbsp;
      | 答题者{{isFirst ? '。' : ''}}

    .designation_top_line
      .tag 获得称号
    .designation {{ info.title }}
    .designation_info {{ info.content }}

    .btns
      .btn.one(@click='tryalign', :class='{ bounceInDown: bounceInDown }') 再试一次
      .btn.two.blue(@click='showAnswer', :class='{ bounceInDown: bounceInDown }') 查看解析
    .line_btn(@click='goShare', :class='{ tada: bounceInDown }') 生成分享图

  AlertBox(ref='payBox')
    .pay_box
      .h1 支持这套题
      .h2 查看所有题目解析
      .icon
        img(src='../assets/img/icon_lq.png')
      .h3 附赠科学声音小程序
      .h3 
        span 全场 5 元优惠券
        | （每人限一张）
      .btn(@click='createOrderPay') 支付 1 元
      .tip 小提示：购买后可无限次查看解析
  AlertBox(ref='errorBox')
    .error_box
      .h1.red 支付失败
      .icon.error
        img(src='../assets/img/error.png')
      .h3 当前未能完成支付，请您重新支付
      .btn.error(@click='createOrderPay') 重新支付
  AlertBox(ref='couponBox')
    .coupon_box
      .title 恭喜您
      .info 获得科学声音小程序
      .coupon
        .l
          .t 全场优惠券
          .b 兑换码：{{ coupon }}
            button.copybtndata.copy(@click='copy', :data-clipboard-text='coupon') 复制
        .r ¥
          span 5
      .tip 说明：复制兑换码后点击“去兑换”，每人限领一次，可用于购买任意专栏
      //- .btns
      .color_btn(@click='goAnswer') 查看解析
      .color_btn.mini(@click='goCoupon') 去兑换

  AlertBox(ref='posterBox', :boxWidth='5')
    .poster(ref='posterContent')

  .share.result(ref='poster')
    .page_bg_img(ref='bgimg')
    .logo(ref='logo')

    .card
      .page_qrcode
        .img(ref='qrcode')
        .share_line
          .fingerprint(ref='fingerprint')
          span 长按测评
      .user_head_img(ref='headimg')
      .nickname {{ userInfo.nickName }}
      .title 恭喜你，完成测评！
      //- .txt 我的测评结果
      .info
        .gary 理性度
          span {{ score }}
          i %
        .gary 感性度
          span {{ 100 - score }}
          i %
      .txt.e 理性度超过&nbsp;
        span {{ proportion }}%&nbsp;
        | 的答题者，感性度超过&nbsp;
        span {{ 100 - proportion }}%&nbsp;
        | 答题者{{isFirst ? '。' : ''}}

      .designation_top_line
        .tag 获得称号
      .designation {{ info.title }}
      .designation_info {{ info.content }}

      .circles
        .circle
        .circle
        .circle
        .circle
        .circle
        .circle
        .circle
        .circle
        .circle
        .circle
        .circle
        .circle
        .circle
</template>

<script>
import { sleep, getQueryString } from '../assets/js/utils'
import AlertBox from '../components/AlertBox'
import ClipboardJS from 'clipboard'
import html2canvas from 'html2canvas'

export default {
  name: 'Result',
  components: {
    AlertBox,
  },
  data() {
    return {
      canCopy: false,
      isPay: false,
      coupon: '',
      bounceInDown: false,

      isFirst: false
    }
  },
  computed: {
    comments() {
      return this.$store.state.comments
    },
    proportion() {
      return (this.$store.state.fraction && this.$store.state.fraction.proportion) || 0
    },
    score() {
      return (this.$store.state.fraction && this.$store.state.fraction.score) || 0
    },
    lv() {
      const score = this.$store.state.fraction && this.$store.state.fraction.score
      if (!score || score <= 34) {
        return 1
      } else if (score <= 59) {
        return 2
      } else if (score <= 68) {
        return 3
      } else if (score <= 76) {
        return 4
      } else if (score <= 83) {
        return 5
      } else if (score <= 89) {
        return 6
      } else if (score <= 94) {
        return 7
      } else if (score <= 97) {
        return 8
      } else if (score <= 99) {
        return 9
      } else {
        return 10
      }
    },
    info() {
      return this.comments[`c${this.lv}`]
    },
    userInfo() {
      return this.$store.state.userInfo
    },
  },
  methods: {
    async getQuizzes() {
      const data = await this.$api.getQuizzes({
        page: 1,
        size: 2
      })
      if (!data || !data.list || !data.list.length || (data.list && data.list.length === 1)) {
        this.isFirst = true
      }
    },
    copy() {
      if (!this.canCopy) return
      this.clipboard = new ClipboardJS('.copybtndata')

      this.clipboard.on('success', (e) => {
        e.clearSelection()
        this.$toast('兑换券已复制')
        this.clipboard && this.clipboard.destroy()
      })
      this.clipboard.on('error', () => {
        this.$toast('复制失败！')
        this.canCopy = false
        this.clipboard && this.clipboard.destroy()
      })
    },
    async tryalign() {
      if (!this.isPay) {
        this.$toast('你只能评测一次哦，查看解析后可多次评测。')
        return
      }
      this.$store.commit('CURRENT_QUIZZE', {})
      this.$store.commit('SET_QUESTIONS_DATA', [])
      this.$router.push('/')
    },
    async getCoupons() {
      try {
        const data = await this.$api.getQuizzesOrdersCoupons()
        this.coupon = data.code
        this.isPay = true
      } catch (e) {
        // e
        this.isPay = false
      }
    },
    async createOrderPay() {
      try {
        const order = await this.$api.postQuizzeOrders(this.$store.state.currentQuizze.id)
        const data = await this.$api.postQuizzeOrdersPaysWechat(order.id, {
          payType: 4,
        })
        // console.log(data)
        window.WeixinJSBridge.invoke(
          'getBrandWCPayRequest',
          {
            appId: data.appID,
            timeStamp: data.timestamp,
            nonceStr: data.nonceStr,
            package: data.package,
            signType: data.signType,
            paySign: data.paySign,
          },
          async (res) => {
            this.$refs.payBox.hide()
            if (res.err_msg === 'get_brand_wcpay_request:ok') {
              this.$loading.show()
              try {
                await sleep(2)
                await this.getCoupons()
                this.$refs.couponBox.show()
              } catch (e) {
                // -
              }
              this.$loading.hide()
            } else {
              this.$refs.errorBox.show()
            }
          }
        )
      } catch (e) {
        // e
      }
    },
    showAnswer() {
      if (this.isPay) {
        this.$refs.couponBox.show()
      } else {
        this.$refs.payBox.show()
      }
    },
    goShare() {
      this.$refs.posterBox.show()
      // this.$router.push('/share')
    },
    goAnswer() {
      this.$router.push('/answer')
    },
    goCoupon() {
      window.location.href = 'https://platform.kexueshengyin.com/couponList'
    },
    async creatPoster() {
      this.$loading.show()
      // 生成海报
      const loadImages = (src, element) => {
        return new Promise((resolve, reject) => {
          const img = document.createElement('img')
          img.src = src
          img.setAttribute('crossOrigin', 'anonymous')
          img.style = 'width:100%'
          element.innerHTML = ''
          element.appendChild(img)
          img.onload = () => {
            resolve('ok')
          }
          img.onerror = (e) => {
            reject(e)
          }
        })
      }

      try {
        await Promise.all([
          loadImages(require(`../assets/img/lv_bg_0${this.lv}.png`), this.$refs.bgimg),
          loadImages(require(`../assets/img/logo_white.png`), this.$refs.logo),
          loadImages(require(`../assets/img/url_qrcode.png`), this.$refs.qrcode),
          loadImages(require(`../assets/img/fingerprint.png`), this.$refs.fingerprint),
          loadImages(this.userInfo.avatarUrl, this.$refs.headimg),
        ])
        window.pageYOffset = 0
        document.documentElement.scrollTop = 0
        document.body.scrollTop = 0
      } catch (e) {
        console.error(e)
      }

      const creatImg = async () => {
        try {
          const canvas = await html2canvas(this.$refs.poster, {
            useCORS: true,
            backgroundColor: null,
          })
          const img = document.createElement('img')
          img.src = canvas.toDataURL('image/png', 1)
          img.style = 'width:100%;display:block;'
          this.$refs.posterContent.appendChild(img)

          const tip = document.createElement('div')
          tip.innerText = '长按图片保存并分享'
          tip.style = 'width:100%;position:absolute;left:0;bottom:.3rem;font-size:.3rem; color:#fff;pointer-events: none;font-weight:bold;z-index:6;text-align:center;text-shadow: 0 1px 1px #666;'
          this.$refs.posterContent.appendChild(tip)
        } catch (e) {
          console.log(e)
        }
      }
      setTimeout(() => {
        creatImg()
        this.$loading.hide()
      }, 300)
    },
  },
  async mounted() {
    // this.$refs.payBox.show()
    this.canCopy = ClipboardJS.isSupported()
    this.getCoupons()
    if (!this.$store.state.currentQuizze || !this.$store.state.currentQuizze.id) {
      this.$router.push('/')
      return
    }
    try {
      await this.$store.dispatch('getFraction')
      this.getQuizzes()
    } catch (e) {
      this.$router.push('/question')
      return
    }
    this.creatPoster()

    setTimeout(() => {
      this.bounceInDown = true
    }, 200)

    const ready = () => {
      this.$regWeChat()
      const code = getQueryString('code')
      if ((!this.$store.state.userInfo.openID || !this.$store.state.userToken.accessToken) && !code) {
        window.location.href.indexOf('/login') < 0 && this.$setExpireStore('fromPath', window.location.href)
        const url = process.env.VUE_APP_API_URL
        window.location.replace(`${url}/login/wechat/web/redirect?state=${encodeURIComponent(window.location.href)}&scope=snsapi_userinfo&redirectURI=${encodeURIComponent(window.location.href)}`)
      }
    }

    if (this.$store.state.isWeixin && (!window.WeixinJSBridge || !WeixinJSBridge.invoke)) {
      document.addEventListener('WeixinJSBridgeReady', ready, false)
    } else {
      ready()
    }
  },
}
</script>

<style lang='stylus' scope>
@keyframes tada
  from
    transform: scale3d(1, 1, 1)

  10%, 20%
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg)

  30%, 50%, 70%, 90%
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)

  40%, 60%, 80%
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)

  to
    transform: scale3d(1, 1, 1)

@keyframes bounceInDown
  from, 20%, 53%, 80%, to
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1)
    transform: translate3d(0, 0, 0)

  40%, 43%
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06)
    transform: translate3d(0, -30px, 0)

  70%
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06)
    transform: translate3d(0, -15px, 0)

  90%
    transform: translate3d(0, -4px, 0)

.result
  background: #0563DA
  background-size: cover
  padding-top: 1.94rem
  position: relative

  &.share
    padding-top: 2.5rem
    background: #0563DA
    background-size: cover
    padding-bottom: 0.9rem
    position: fixed
    left: 150vw
    top: 0
    z-index: 9
    width: 100%

    .card
      border-radius: 0.24rem 0.24rem 0 0
      padding-bottom 1rem

  .page_bg_img
    position: absolute
    top: 0
    left: 0
    z-index: 1
    width: 7.5rem
    height: auto

    img
      width: 100%

  .logo
    width: 2.22rem
    height: 0.59rem
    position: absolute
    z-index: 5
    left: 0.3rem
    top: 0.3rem

    img
      width: 100%
      height: 100%

  .card
    background: #FFFFFF
    border-radius: 0.24rem
    margin: 0 0.16rem
    padding: 0.7rem 0.32rem
    position: relative
    z-index: 2

  .page_qrcode
    display: flex
    flex-flow: column nowrap
    align-items: center
    justify-content: center
    position: absolute
    right: 0.3rem
    top: 0.5rem

    .img
      line-height: 0
      width: 1.42rem
      height: 1.42rem

      img
        width: 1.42rem
        height: 1.42rem

    .share_line
      margin-top: 0.1rem
      display: flex
      align-items: center
      justify-content: center

      .fingerprint
        line-height: 0
        width: 0.3rem
        height: 0.3rem

      img
        width: 0.3rem
        height: 0.3rem

      span
        font-size: 0.24rem
        line-height: 1
        margin-left: 0.1rem
        color: #606060

  .user_head_img
    width: 1rem
    height: 1rem
    border-radius: 50%
    overflow: hidden
    border: 1px solid #fff
    position: absolute
    top: -0.6rem
    left: 0.3rem
    box-shadow: 0 0.05rem 0.08rem rgba(0, 0, 0, 0.1)
    line-height: 0

    img
      width: 100%
      height: 100%

  .nickname
    font-size: 0.28rem
    color: #333333
    line-height: 1

  .title
    font-size: 0.4rem
    line-height: 1
    margin-top: 0.3rem
    color: #333333
    font-weight: bold

  .txt
    font-size: 0.24rem
    color: #666666
    line-height: 1
    margin-top: 0.3rem

    &.e
      color: #999

      span
        color: #666

  .info
    display: flex
    align-items: center
    margin-top: 0.15rem

    .gary
      font-size: 0.28rem
      color: #333333
      margin-right: 0.3rem
      display: flex
      align-items: center

      span
        color: #0B73F7
        margin-left: 0.15rem
        font-size: 0.48rem
        position: relative
        top: 0.05rem
        font-weight: bold

      i
        color: #0B73F7
        position: relative
        top: 0.1rem

  .designation_top_line
    display: flex
    flex-flow: row no-wrap
    align-items: center
    justify-content: space-between
    margin-top: 0.4rem

    .tag
      background: #1068DC
      border-radius: 0.04rem 0.04rem 0.33rem 0.04rem
      padding: 0.1rem 0.2rem
      color: #fff
      display: flex
      align-items: center
      justify-content: center
      font-size: 0.24rem

  .designation
    font-size: 0.54rem
    color: #494949
    letter-spacing: 0.03rem
    text-align: center
    font-weight: bold
    padding: 0.1rem
    margin-top: 0.3rem
    position: relative

    &:before, &:after
      display: block
      content: ' '
      width: 0.15rem
      height: 0.2rem
      border: 0.1rem solid #0563DA
      position: absolute

    &:before
      left: 0
      top: 0
      border-right-color: transparent
      border-bottom-color: transparent

    &:after
      right: 0
      bottom: 0
      border-left-color: transparent
      border-top-color: transparent

  .designation_info
    margin-top: 0.3rem
    font-size: 0.24rem
    color: #999999
    line-height: 0.4rem

  .btns
    width: 100%
    margin: 0 auto
    display: flex
    flex-flow: row no-wrap
    align-items: center
    justify-content: space-between
    margin-top: 0.45rem

    .btn
      background: #FFFFFF
      border: 1px solid #979797
      border-radius: 0.5rem
      height: 1rem
      width: 3.05rem
      font-size: 0.36rem
      color: #999999
      display: flex
      flex-flow: row no-wrap
      align-items: center
      justify-content: center

      &.bounceInDown
        animation: bounceInDown 1s linear 1

      &.one
        animation-delay: 0s

      &.two
        animation-delay: 1s

      &.blue
        color: #fff
        border-color: #0563DA
        background: #0563DA

  .line_btn
    font-size: 0.24rem
    color: lighten(#0563DA, 30%)
    text-align: center
    padding: .1rem 0.3rem
    border-radius .5rem
    border 1px solid lighten(#0563DA, 30%)
    display: block
    width: 1.8rem
    margin: .3rem auto 0

    &.tada
      animation: tada 1s linear 1
      animation-delay: 2s

  .circles
    position: absolute
    bottom: -.2rem
    height: 0.4rem
    width 100%
    margin: 0 -0.3rem
    display: flex
    align-items: center
    justify-content: space-around

    .circle
      width: 0.4rem
      height: 0.4rem
      background: #0563DA
      border-radius: 0.2rem
      position: relative
      // top: 0.6rem

  .pay_box, .error_box, .coupon_box
    padding: 0.4rem 0 0.5rem
    display: flex
    flex-flow: column nowrap
    align-items: center
    justify-content: center

    .h1
      font-size: 0.42rem
      line-height: 1
      color: #0563DA

      &.red
        color: #FE6D68

    .h2
      font-size: 0.36rem
      line-height: 1
      color: #333333
      margin-top: 0.3rem

    .h3
      font-size: 0.28rem
      line-height: 1.6
      color: #333333

      span
        color: #0563DA

    .icon
      width: 3.4rem
      height: 2.8rem
      margin: 0.4rem 0 0.3rem

      &.error
        width: 1.7rem
        height: 1.7rem
        margin: 0.6rem 0 0.6rem

      img
        width: 100%
        height: 100%

    .btn
      width: 80%
      height: 0.9rem
      text-align: center
      line-height: 0.9rem
      background: #0563DA
      border-radius: 0.45rem
      font-size: 0.36rem
      color: #FFFFFF
      margin-top: 0.3rem

      &.error
        background: #FE6D68

    .tip
      margin-top: 0.3rem
      font-size: 0.24rem
      color: #999999
      line-height: 1

  .coupon_box
    background-image: linear-gradient(180deg, #000000 0%, #10A0FF 0%, #0563DA 100%)
    border-radius: 0.28rem
    overflow: hidden

    .title
      font-size: 0.6rem
      line-height: 1
      color: #FFFFFF

    .info
      font-size: 0.36rem
      line-height: 1
      color: #FFFFFF
      margin-top: 0.3rem

    .coupon
      background: #FFFFFF
      box-shadow: 0 0.07rem 0.18rem 0 rgba(0, 0, 0, 0.19)
      display: flex
      align-items: center
      justify-content: space-between
      margin-top: 0.3rem
      border-radius: 0.26rem
      width: 5.5rem
      padding: 0.3rem 0.5rem
      box-sizing: border-box
      position: relative

      .l
        display: flex
        flex-flow: column nowrap
        align-items: flex-start

        .t
          font-size: 0.48rem
          color: #C45F00

        .b
          display: flex
          align-items: center
          margin-top: 0.1rem

          .copy
            margin-left: 0.1rem
            font-size: 0.24rem
            color: #0564DA
            display: inline-block
            padding: 0

      .r
        color: #C45F00
        font-size: 0.48rem
        padding-left: 0.2rem
        border-left: 1px solid #ddd

        span
          font-size: 1rem

    .tip
      width: 4rem
      line-height: 1.6
      margin-top: 0.3rem
      font-size: 0.24rem
      color: #FFFFFF

    .color_btn
      height: 0.9rem
      line-height: 0.9rem
      width: 80%
      font-size: 0.36rem
      color: #C45F00
      margin-top: 0.3rem
      background-image: linear-gradient(180deg, #FCE589 0%, #FBCC5D 100%)
      border-radius: 0.45rem

      &.mini
        background-image: linear-gradient(180deg, #fff 0%, #ddd 100%)

.poster
  line-height: 0

</style>
